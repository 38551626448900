.socials {
	list-style: none;
	padding: 0;
	display: flex;
	align-items: center;
	width: 100%;
	margin: 0 0 44px;

	@media (max-width: 991px) {
		margin-bottom: 32px;
	}

	&__item {
		padding-bottom: 10px;

		&:not(:last-child) {
			margin-right: 2.25rem;
		}
	}
}
