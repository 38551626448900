h1,
.h1 {
	font-size: 4rem;
	font-weight: 700;
	margin-bottom: 1.75rem;
}

h2,
.h2 {
	font-size: 2.5rem;
	font-weight: 700;
}

h3,
.h3 {
	font-size: 1.875rem;
	font-weight: 700;
}
h4,
.h4 {
	font-size: 1.25rem;
	font-weight: 700;
}

p,
.paragraph {
	font-size: 1rem;
	font-weight: 500;
}

.font-medium {
	font-weight: 500;
}
.font-bold {
	font-weight: 500;
}

.font-size-sm {
	font-size: 0.875rem;
}


body, html, * {
	font-family: 'Inter', sans-serif;
}