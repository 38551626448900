.modal-header {
	border-bottom-color: rgba(255, 255, 255, 0.5);
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-height: 85px;

	.close {
		margin-left: 0;
	}
}

.modal-title {
	font-weight: 700;
	font-size: 1.25rem;
}

.modal-content {
	border-radius: 8px;
	background-color: $modal-bg;
	border: none;
	box-shadow: -1px 11px 43px rgba(0, 0, 0, 0.12);
}

.modal-backdrop {
	background: rgba(33, 36, 41, 1);

	&.show {
		opacity: 0.6;
	}
}

.custom-modal {
	max-width: 674px;
	.modal-content {
		max-width: 674px;
	}

	.modal-body,
	.modal-header {
		padding: 30px;
	}
}