@import "../../styles/abstracts/variables";

.header {
	background-color: transparent;
	min-height: 70px;

	&.navbar {
		padding-top: 0;
		padding-bottom: 0;
	}

	&__inner {
		min-height: 70px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		@media (max-width: 1200px) {
			width: 100%;
		}
	}

	.navbar-brand {
		margin-right: 4px;
	}

	&__logo {
		display: flex;
		align-items: center;
		text-decoration: none !important;
		position: relative;

		&-img {
			width: 35px;
			height: 25px;
			margin-right: 6px;
		}

		&-title {
			font-weight: bold;
			font-size: 15px;
		}
	}

	&__nav {
		align-items: center;
	}

	&__item {
		text-decoration: none !important;
		color: $title;
		font-weight: 500;
		font-size: 1rem;
		padding: 1rem 1.625rem;
		position: relative;

		&:hover {
			color: $overline;
		}

		&::before {
			content: "";
			position: absolute;
			bottom: 0.625rem;
			left: 1.625rem;
			right: 1.625rem;
			height: 1px;
			background-color: $primary;
			transform: scaleX(0);
			transform-origin: left center;
			transition: 0.4s ease all;
			will-change: transform;
		}

		&--active::before {
			transform: scaleX(1);
			transform-origin: right center;
		}
	}
}
