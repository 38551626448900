@import "../../../styles/abstracts/variables";

.features {
	padding: 20px 0 0;

	@media (min-width: 576px) {
		padding: 35px 0 0;
	}

	.slick-slide {
		height: 100%;
		padding: 0 10px;
	}

	&__container {
		width: 100%;
		display: block;
		overflow: hidden;
	}

	&__inner {
		width: calc(100% + 20px);
		margin-left: -10px;
		padding-bottom: 24px;
	}

	&__dots {
		list-style: none;
		padding-top: 0;
		padding-left: 0;
		margin: 0;
		display: flex !important;
		align-items: center;
		position: relative;
		justify-content: center;

		@media (min-width: 576px) {
			padding-top: 10px;
		}

		& li {
			button {
				width: 5px !important;
				height: 5px !important;
				border-radius: 5px;
				opacity: 0.5;
				background-color: $primary;
				transition: opacity 0.4s ease;
				margin: 0 5px;
				padding: 0;

				@media (min-width: 576px) {
					width: 10px !important;
					height: 10px !important;
					border-radius: 10px;
				}

				&::before {
					display: none;
				}
			}

			&.slick-active button {
				opacity: 1;
			}
		}
	}
}
