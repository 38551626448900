@import "../../../styles/abstracts/variables";

.banners {
	padding: 40px 0 25px;

	@media (min-width: 576px) {
		padding-top: 80px;
		padding-bottom: 75px;
	}

	&__container {
		width: 100%;
		display: block;
		overflow: hidden;
		margin: 0 15px;
	}

	&__inner {
		width: calc(100% + 53px);
		margin-left: -27px;
		padding-bottom: 24px;
	}
}

.banner {
	&__link {
		display: block;
		padding: 100px 27px 0;
		width: 100%;

		&:focus {
			outline: none;
		}
	}

	&__dots {
		list-style: none;
		padding-top: 0;
		padding-left: 0;
		margin: 0;
		display: flex !important;
		align-items: center;
		position: relative;
		justify-content: center;

		@media (min-width: 576px) {
			padding-top: 10px;
		}

		& li {
			button {
				width: 5px !important;
				height: 5px !important;
				border-radius: 5px;
				opacity: 0.5;
				background-color: $primary;
				transition: opacity 0.4s ease;
				margin: 0 5px;
				padding: 0;

				@media (min-width: 576px) {
					width: 10px !important;
					height: 10px !important;
					border-radius: 10px;
				}

				&::before {
					display: none;
				}
			}

			&.slick-active button {
				opacity: 1;
			}
		}
	}
}
