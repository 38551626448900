@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "styles/abstracts/variables";
/*!
 * Bootstrap Grid v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";

@import "../node_modules/bootstrap/scss/mixins/deprecate";
@import "../node_modules/bootstrap/scss/mixins/breakpoints";
@import "../node_modules/bootstrap/scss/mixins/grid-framework";
@import "../node_modules/bootstrap/scss/mixins/grid";

@import "../node_modules/bootstrap/scss/grid";
@import "styles/main.scss";
